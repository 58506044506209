import React from "react";
import { SuperTag } from "~components";
import SectionTitle from "./style";
import Feature from "~sections/landingpage/componentsLanding/Vende/style";
export default function SectionTitleBlock({
  subTitleProps,
  titleProps,
  textProps,
  subTitle,
  title,
  text,
  ...rest
}) {
  return (
    <SectionTitle {...rest}>
      <SectionTitle.Subtitle {...subTitleProps}>
        <Feature.Titulos>
          <SuperTag value={subTitle} />
        </Feature.Titulos>
      </SectionTitle.Subtitle>
      <SectionTitle.Main {...titleProps}>
        <SuperTag value={title} />
      </SectionTitle.Main>
      {text ? (
        <SectionTitle.Text {...textProps}>
          <SuperTag value={text} />
        </SectionTitle.Text>
      ) : null}
    </SectionTitle>
  );
}