import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage as Img } from "gatsby-plugin-image";
import SectionTitle from "./Components/SectionTitle";
import About from "./style";
import panelCambio from "../../../../assets/image/landing/panelCambio2.png";
export default function AboutusSection() {
  return (
    <About>
      <Container>
        <Row className="align-items-center justify-content-center g-0">
          <Col xs="12" className="col-xxl-6 col-lg-5 col-md-10">
              <About.Image>
                <img
                  src={panelCambio}
                  style={{ width: "100%" }}
                  alt="Pin Notification"
                ></img>
              </About.Image>
          </Col>
          <Col className="col-xxl-6 col-lg-7 col-md-10">
            <About.Box>
              {/* Section Title */}
                <About.Content>
                  <SectionTitle
                    subTitle="Conecta con la ciudad entera"
                    title="Expande tu alcance con MiEmpeño Partners"
                    text="MiEmpeño eleva tu negocio, asegurando accesibilidad en toda la ciudad. Nuestra plataforma y logística extienden tus servicios a todos los clientes, sin importar su ubicación. Únete a MiEmpeño y transforma tu alcance urbano."
                    subTitleProps={{
                      mb: "10px",
                      className: "subline",
                      fontColor: "#5034fc",
                    }}
                    titleProps={{ mb: "10px", as: "h2" }}
                    mb="69px"
                  />
                </About.Content>

              {/*/ .Section Title */}
            </About.Box>
          </Col>
        </Row>
      </Container>
    </About>
  );
}
