import {Images} from "~data"
const TestimonialData = {
    testimonial: [
        {
          id:"ts1",
          image:Images.landing.roberto1,
          icon:"fa fa-quote-left",
          userName: "Roberto Sayago",
          userPosition:"San Ángel",
          text:"“Mi Empeño ha demostrado ser un socio eficaz, asegurando un flujo constante y confiable de artículos, lo que ha mantenido nuestro inventario dinámico.”",
          userPuesto:"Director"
        },
        {
          id:"ts3",
          image:Images.landing.esmeralda,
          icon:"fa fa-quote-left",
          userName: "Esmeralda Mosqueda",
          userPosition:"Multicash",
          text:"“La colaboración con Mi Empeño ha impulsado nuestros empeños e ingresos, superando los resultados de un mes típico.”",
          userPuesto:"Directora de operaciones"
        },
        {
          id:"ts2",
          image:Images.landing.roberto2,
          icon:"fa fa-quote-left",
          userName: "Raúl Guadarrama",
          userPosition:"OriCrédito",
          text:"“Nuestra asociación con Mi Empeño ha optimizado el acceso a electrónicos y mejorado nuestras operaciones. ¡Altamente recomendado!”",
          userPuesto:"Fundador"
        }
    ]
}
  export default TestimonialData;