import styled from "styled-components/macro";
import { Box } from "~styled";

const About = styled(Box)`
overflow: hidden;
  padding-top: 35px;
  padding-bottom: 5px;
  background-color: #fff;
  @media (min-width: 768px) {
    padding-top: 75px;
    padding-bottom: 25px;
  }

  @media (min-width: 992px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`;

About.ImageContent = styled(Box)`
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
  @media (max-width: 764px) {
    padding-bottom: 30px;
  }
`;
About.Shape = styled(Box)`
  position: absolute;
  top: 50%;
  left: 56%;
  z-index: -1;
  transform: translate(-50%, -50%);
  max-height: 50vh;
  height: 100%;
  max-width: 50vh;
  width: 100%;
`;
About.Div = styled(Box)`
  background-color: rgba(80, 52, 252, 0.9);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: auto;

`;

About.Box = styled(Box)``;

export default About;
