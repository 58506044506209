import React from "react";
import Promo from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "~data";
export default function PromoSection({ ...rest }) {
  return (
    <Promo>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-xl-12 text-center">
              <Promo.InnerWrapper
                style={{ backgroundImage: `url(${Images.landing.desierto})` }}
              >
                <Promo.Title as="h2" fontColor="#fff">
                  Digitaliza tu casa de empeño
                </Promo.Title>
                <Promo.Text fontColor="#fff">
                  Registrate GRATIS ahora mismo y obtén tu membresía con los
                  beneficios que ofrecemos para tu negocio.
                </Promo.Text>
                <Promo.Button
                  className="btn-torch-red"
                  href="#"
                  rounded={true}
                  sizeX="180px"
                  sizeY="56px"
                  mt="20px"
                >
                  Registrar
                </Promo.Button>
              </Promo.InnerWrapper>
            </Col>
          </Row>
        </Container>
    </Promo>
  );
}
