import React from "react";

import HeaderButton from "~sections/services/Header";

/* Nuevos componentes importados */
//Barra de navegación
import PageWrapper from "~sections/landingpage/components/NavBar/PageWrapper";
//Header
import HeroSection from "~sections/landingpage/componentsLanding/Header/HeroSection";

//Sección de "Multiplica tus contratos"
import FirstSection from "~sections/landingpage/componentsLanding/Multiplica/AboutSection";
//Sección de Expande tu alcance
import AboutusSection from "~sections/landingpage/componentsLanding/Expande/AboutSection";
//Sección de "Vende como Superhéroe"
import FeatureSection from "~sections/landingpage/componentsLanding/Vende/FeatureSection";
//Sección de "Digitaliza tu casa de empeño"
import PromoSection from "~sections/landingpage/componentsLanding/Promo";
//Sección de "Casas de empeño (funcionalidades)"
import AboutSection from "~sections/landingpage/componentsLanding/About/AboutSection";
//Sección de "Testimonios"
import TestimonialSection from "~sections/landingpage/componentsLanding/Testimonial/TestimonialSection";
//Sección de "Se parte de nuestras casas de empeño"
import CtaSection from "~sections/landingpage/componentsLanding/Cta/CtaSection";

import FooterFour from "~sections/landingpage/components/FooterFour";
import ButtonLogin from "~sections/services/ButtonLogin";
/*  */
const header = {
  headerClasses:
    "site-header site-header--menu-center site-header--services dark-header site-header--sticky site-header--service",
  containerFluid: false,
  darkLogo: false,
  buttonBlock: (
    <>
      <ButtonLogin
        className="ms-auto d-none d-xs-inline-flex"
        btnText="Iniciar Sesión"
        mr="15px"
        mrLG="0"
        border="none"
        path="login"
      />
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnText="Registrar"
        mr="15px"
        mrLG="0"
      />
    </>
  ),
};

export default function Services() {
  return (
    <PageWrapper headerConfig={header}>
      <HeroSection />
      <FirstSection />
      <AboutusSection />
      {/* Recientemente agregados */}
      <FeatureSection />
      <PromoSection />
      <AboutSection />
      <TestimonialSection />
      <CtaSection />
      <FooterFour />
      {/*  */}
    </PageWrapper>
  );
}
