import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Feature from "./style";
import SectionTitle from "./Components/SectionTitle";
import CounterBlock from "./Components/CounterBlock";

import Integration from "./style_integrations";
import { Images } from "~data";
import { useRef } from "react";
export default function FeatureSection() {
  const scrollRef = useRef(null)

  return (
    <Feature backgroundColor="#fff">
      <Container>
        <Row>
          <Col className="col-xl-8 col-lg-10">
              <Feature.Box>
                <SectionTitle
                  subTitle="En tiempo record"
                  title="¡Vende como Superhéroe!"
                  titleProps={{ mb: "33px" }}
                  subTitleProps={{ mb: "20px" }}
                />
              </Feature.Box>
          </Col>
        </Row>

        <Row className="align-items-center justify-content-center">
          <Col xs="12" className="col-lg-6 col-md-12">
              <img className="w-100" src={Images.landing.esquema}></img>
          </Col>

          <Col className="col-lg-6 col-md-12">
              <Feature.Box>
                <Feature.Text>
                  Como Partner, obtendrás acceso exclusivo a diversas
                  plataformas de Marketplace online, lo que te permitirá
                  expandir significativamente tu alcance comercial y conectar
                  con una amplia red de clientes potenciales.
                </Feature.Text>
                <Feature.Contentlist mbLG="65px" mb="30px">
                  <Feature.List>TuSiguienteCompra.com</Feature.List>
                  <Feature.List>Facebook</Feature.List>
                  <Feature.List>Instagram</Feature.List>
                  <Feature.List>Mercado Libre</Feature.List>
                </Feature.Contentlist>
                  <CounterBlock />
              </Feature.Box>
          </Col>
        </Row>
      </Container>
    </Feature>
  );
}
