import React from 'react'
import Card  from './style'
export default function TestiomialCard({userName, userPosition,text, icon,image,userPuesto ,...rest}){
  return(
  <Card className='h-full'>
    <Card.Body backgroundColor="#fff">
      <Card.Text>{text}</Card.Text>
        <Card.UserBlock>
          <Card.Image>
            <img src={image} alt="Testimonial" />
          </Card.Image>
          <Card.UserTexts>
            <Card.Title as="h3">{userName}</Card.Title>
            <Card.UserPosition>{userPosition}</Card.UserPosition>
            <Card.Puesto>{userPuesto}</Card.Puesto>
          </Card.UserTexts>
        </Card.UserBlock>
    </Card.Body>
    <Card.Icon>
      <i className={icon} />
    </Card.Icon>
  </Card>
)
}