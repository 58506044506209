import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "./components/SectionTitle";
import Widget from "./components/widget";
import { StaticImage as Img } from "gatsby-plugin-image";
import About from "./style";
import Computer from "../../../../assets/image/landing/computer.png";
export default function AboutSection() {
  return (
    <About backgroundColor="#f2f5fb">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              subTitle="Panel especializado para"
              title="Casas de empeño"
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">

              <Widget
                directionXS="row-reverse"
                title="Administra tu casa de empeño"
                icon="fa fa-archive"
                text="Herramientas para gestionar el sitio web del negocio"
              />

              <Widget
                directionXS="row-reverse"
                title="Dirige tu catálogo"
                icon="fa fa-book"
                text="Gestiona del inventario de artículos."
              />
              <Widget
                directionXS="row-reverse"
                title="Evalúa tu negocio"
                icon="fa fa-bar-chart-o"
                text="Obtén herramientas analíticas y reportes."
              />
          </Col>
          <Col
            xs="12"
            className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center"
          >
              <About.ImageContent>
                <img
                  src={Computer}
                  style={{ width: "100%" }}
                  alt="Computer"
                ></img>
                <About.Shape>
                  {/*                <Img src="../../../assets/image/home-app/green-shape.png" alt="content" layout="fullWidth" placeholder="blurred"/>
                   */}{" "}
                  <About.Div></About.Div>
                </About.Shape>
              </About.ImageContent>
           </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
              <Widget
                directionXS="row"
                title="Controla tus empeños/ventas"
                icon="fa fa-file-text"
                text="Usa una plataforma integrada para gestionar todo."
              />
              <Widget
                directionXS="row"
                title="Contacta a soporte"
                icon="fa fa-headphones"
                text="Resuelve problemas rápidamente."
              />
              <Widget
                directionXS="row"
                title="Tranferencias seguras"
                icon="fa fa-credit-card"
                text="Tu seguridad y la de tus clientes son primordiales."
              />
          </Col>
        </Row>
      </Container>
    </About>
  );
}
