import styled from 'styled-components/macro';
import { Box, Heading, Paragraph } from '~styled';

const About = styled(Box)`
    padding-top: 60px;
    padding-bottom: 60px;
    @media (min-width:768px) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media (min-width:992px) {
        padding-top: 125px;
        padding-bottom: 130px;
    }
`

About.SubTitle = styled(Heading)`

`

About.Title = styled(Heading)`

`

About.Content = styled(Heading)`
background: rgb(199,84,1);
background: linear-gradient(0deg, rgba(199,84,1,1) 0%, rgba(252,213,110,1) 55%);

padding-left: 30px;
padding-top: 30px;
padding-bottom: 30px;
padding-right: 30px;
z-index:-1;
border-radius: 20px;
@media (min-width: 576px){
    padding-left: 80px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 80px;
}
@media (min-width: 992px){
    padding-left: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 30px;
}
@media (min-width: 1200px){
    padding-left: 90px;
    padding-top: 85px;
    padding-bottom: 85px;
    padding-right: 40px;
}
`

About.Text = styled(Paragraph)`

`

About.Image = styled(Box)`
z-index: 1;
border-radius: 5px;
position: relative;
@media (min-width: 992px){
    width: 107%;
}
`

About.Box = styled(Box)`

`

export default About;