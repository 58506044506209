import React from "react";
import Cta from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "~data";
export default function CtaSection({ ...rest }) {
  return (
    <Cta backgroundImage={Images.agency.promoBg}>
        <Container>
          <Row className="justify-content-center">
            <Col className="col-xl-6 col-lg-8 col-md-10">
              <Cta.InnerWrapper className="text-center">
                <Cta.Title as="h2" fontColor="#fff">
                  Sé parte de las Casas de empeño en línea
                </Cta.Title>
                <Cta.Button
                  className="btn-torch-red"
                  href="#"
                  rounded={true}
                  sizeX="180px"
                  sizeY="56px"
                >
                  Registrate ahora
                </Cta.Button>
              </Cta.InnerWrapper>
            </Col>
          </Row>
        </Container>
    </Cta>
  );
}
