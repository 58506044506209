import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { StaticImage as Img } from "gatsby-plugin-image";
import CounterBlock from "./Components/CounterBlock";
import About from "./style";
import NotificacionesPanel from "../../../../assets/image/landing/Panel-notificacion.png";
export default function FirstSection({ ...rest }) {
  return (
    <About backgroundColor="#F3F4F6" {...rest}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col
            xs="12"
            className="col-xl-6 col-lg-7 col-md-10 order-2 order-xl-1"
          >
              <About.Box mrXL="50px" mt="0" mtXL="0" pb="40px" pbLG="0">
                <About.ContentTextBlock className="text-center text-xl-end">
                  <About.Subtitle as="h6" fontColor="rgba(80, 52, 252, 0.9)">
                    Genera alta demanda
                  </About.Subtitle>
                  <About.Title as="h2" pb="35px" mb="40px">
                    Multiplica tus contratos
                  </About.Title>
                  <About.Text>
                    Como Partner, tus contratos aumentarán automáticamente.
                    MiEmpeño generará demanda específica para cada sucursal.
                  </About.Text>
                </About.ContentTextBlock>
                <CounterBlock mt="50px" />
              </About.Box>
          </Col>
          <Col
            xs="12"
            className="col-xl-6 col-lg-6 col-md-8 col-sm-9 order-1 order-xl-2"
          >
            {/* <Img  src="../../../assets/image/landing/Notificacionespanel.png" alt="About" placeholder="blurred" layout="fullWidth" objectFit='cover' quality={100}/> */}
              <img
                src={NotificacionesPanel}
                style={{ width: "100%" }}
                alt="Notificaciones Panel"
              ></img>
          </Col>
        </Row>
      </Container>
    </About>
  );
}
