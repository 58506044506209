import React from 'react'
import Counter from './style'
 
export default function CounterBlock({...rest}){
return(
<Counter {...rest}>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter">x2 a 5</span></Counter.Title>
        <Counter.Text>Más contratos</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter">$0</span>%</Counter.Title>
        <Counter.Text>Costo de adquisición</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3"><span className="counter">365 dias</span></Counter.Title>
        <Counter.Text>Del año disponible</Counter.Text>
    </Counter.Single>
</Counter>

)
}