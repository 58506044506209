import React from 'react'
import Counter from './style'
 
export default function CounterBlock({...rest}){
return(
<Counter {...rest}>
    <Counter.Wrapper>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">-50%</span></Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Tiempo de venta</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">+5</span></Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Canales de venta</Counter.Text>
    </Counter.Single>
    <Counter.Single>
        <Counter.Title as="h3" fontColor="#fff"><span className="counter">+250k</span></Counter.Title>
        <Counter.Text fontColor="#ffffffb3">Clientes potenciales</Counter.Text>
    </Counter.Single>
    </Counter.Wrapper>
</Counter>
)
}