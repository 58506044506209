import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Hero from "./style";
import { Link } from "~components";
import { Images } from "~data";
export default function HeroSection() {
  return (
    <Hero
      overlay={Images.Services.heroImgOverlay}
      style={{ backgroundImage: `url(${Images.Services.heroImg})` }}
    >
      <Container>
        <Row className="align-items-center justify-content-center justify-content-md-start">
          <Col className="col-xxl-7 col-xl-7 col-lg-9 col-md-10">
            <Hero.Content>
              <Hero.Title as="h2" fontColor="#fff">
                Dale superpoderes a tu casa de empeño
              </Hero.Title>
                <Hero.Text fontColor="#fff">
                  Crea tu cuenta ahora mismo e incrementa tus contratos
                  <br className="d-none d-sm-block" />
                  de empeño, compras y ventas hasta 4x.
                </Hero.Text>
                <Link
                  target="_blank"
                  href="https://panel-partners-xi.vercel.app/auth/boxed-signup"
                >
                  <Hero.NewsletterButton
                    rounded={true}
                    className="btn-torch-red"
                  >
                    Crear cuenta ahora
                  </Hero.NewsletterButton>
                </Link>
            </Hero.Content>
          </Col>
        </Row>
      </Container>
    </Hero>
  );
}
